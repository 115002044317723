
import { AccResponsePremiumProject } from './index';
import { AccTimeEntryComments } from './index';
import { AccTimeEntryCommentsAudit } from './index';
import { AccTimeEntryHeader } from './index';
import { AccTimeEntryHeaderAudit } from './index';
import { AllHughesNetUsers } from './index';
import { HrClearanceStatus } from './index';
import { AllSites } from './index';
import { AccTimeEntryCodes } from './index';
import { HrDepartments } from './index';
import { AllStatesOrTerritories } from './index';
import { HrEmployeeJobAssignments } from './index';
import { HrEmployeeStatus } from './index';
import { HrEmploymentStatus } from './index';
import { HrEmploymentType } from './index';
import { EngCadDrawingHistory } from './index';
import { EngCadDrawings } from './index';
import { FacEquipmentDocument } from './index';
import { FacEquipmentMeterHistory } from './index';
import { FacFuelIssues } from './index';
import { FacFuelLevels } from './index';
import { FacFuelOther } from './index';
import { FacFuelPeriodStatus } from './index';
import { FacFuelReceipts } from './index';
import { FacGeneratorsDeadlined } from './index';
import { FacMogasEstimates } from './index';
import { FacOilAdditions } from './index';
import { FacPressureReadings } from './index';
import { FacRefrigerantCylinders } from './index';
import { FacRefrigerantTransfers } from './index';
import { FacWasteDrums } from './index';
import { FacWeeklyStatusCompleted } from './index';
import { HrGenderType } from './index';
import { HrEmployeeXOtherSpecialtyType } from './index';
import { HrEmployeeXShareholderType } from './index';
import { HrFyPayRates } from './index';
import { HrOcc } from './index';
import { HrPsc } from './index';
import { HrTravelBagDefaults } from './index';
import { HrMaritalStatus } from './index';
import { NgPermissions } from './index';
import { NgUsers } from './index';
import { OpsScheduledAudit } from './index';
import { OpsScheduled } from './index';
import { OpsTravelRequests } from './index';
import { OpsVacationBids } from './index';
import { QcInspectionReports } from './index';
import { SchResources } from './index';
import { SchScheduleItem } from './index';
import { SchTravelRequests } from './index';
import { NgTenants } from './index';
import { HrJobTitles } from './index';
import { TrQtHistory } from './index';
import { TrQtNotRequired } from './index';
import { TrQualHistory } from './index';
import { TrQualNotRequired } from './index';
import { TrQualXAssigned } from './index';
import { TrQuals } from './index';
import { TrTrainers } from './index';
import { Ttt332 } from './index';
import { HrUnionAffiliation } from './index';
import { WoClassificationHistory } from './index';
import { WoScheduledMaintenanceTemplates } from './index';
import { WoWorkOrderDetailLabor } from './index';
import { WoWorkOrderDetails } from './index';
import { WoWorkOrders } from './index';
import { HrWorkSections } from './index';

export class HrEmployees {
   public employeeId: number = 0;
   public employeeNumber: string = null;
   public lastName: string = null;
   public firstName: string = null;
   public middleName: string = null;
   public mi: string = null;
   public fullName: string = null;
   public currentSiteId: number = null;
   public permanentSiteId: number = null;
   public ssn: string = null;
   public employeeJobAssignmentId: number = 0;
   public siteReliefOverHire: string = null;
   public aka: string = null;
   public originalDateofHire: Date = null;
   public pmcfrontecArctecHireDate: Date = null;
   public bridgedDateofHire: Date = null;
   public probationaryCompletionDate: Date = null;
   public occDate: Date = null;
   public payrollSiteCode: number = null;
   public assignmentStartDate: Date = null;
   public assignment: string = null;
   public latestRevisionDate: Date = null;
   public lastEoaDate: Date = null;
   public termDateLayoffDate: Date = null;
   public returnTravelorRecallRights: string = null;
   public homeAddress: string = null;
   public homeCity: string = null;
   public homeZip: string = null;
   public mailingAddress: string = null;
   public mailingCity: string = null;
   public mailingZip: string = null;
   public homePhone: string = null;
   public messagePhone: string = null;
   public additionalInfo: string = null;
   public emergencyContactLastName: string = null;
   public emergencyContactFirstName: string = null;
   public emergencyContactPhoneNo1: string = null;
   public emergencyContactPhoneNo2: string = null;
   public birthdate: Date = null;
   public veteran: boolean = false;
   public notesMissingInformation: string = null;
   public loginName: string = null;
   public exempt: boolean = false;
   public i9complete: boolean = false;
   public eeocomplete: boolean = false;
   public onSite: boolean = false;
   public titleId: number = 0;
   public siteReliefForId: string = null;
   public workSectionId: number = 0;
   public departmentId: number = 0;
   public employeePhoto: Array<any> = [];
   public officePhone: string = null;
   public arctecCellPhone: string = null;
   public extension: string = null;
   public officeFax: string = null;
   public clearanceStatusId: number = null;
   public clearanceDate: Date = null;
   public lastPromotionDate: Date = null;
   public shareholder: boolean = false;
   public buyerId: string = null;
   public materialContoller: boolean = false;
   public buyer: boolean = false;
   public expeditor: boolean = false;
   public employeeSignature: Array<any> = [];
   public cacRequestToGov: Date = null;
   public cacExpireDate: Date = null;
   public returnFromSite: Date = null;
   public o365plan: string = null;
   public altEmail: string = null;
   public defaultSiteId: number = null;
   public benefitFormComplete: boolean = false;
   public lastEoaby: string = null;
   public lastRehireBy: string = null;
   public bodyWeight: number = null;
   public dd2760: boolean = false;
   public handBookReceipt: Date = null;
   public updateComplete: boolean = false;
   public defaultPaySiteId: number = null;
   public defaultPayTaskId: number = null;
   public companyPurchaseAuth: number = null;
   public oapurchaseAuth: number = null;
   public govtPurchaseAuth: number = null;
   public employmentStatusId: number = 0;
   public employmentTypeId: number = 0;
   public eeoId: number = null;
   public unionAffiliationId: number = 0;
   public employeeStatusId: number = 0;
   public homeStateId: number = null;
   public mailingStateId: number = null;
   public driversLicenseStateId: number = null;
   public driversLicense: string = null;
   public driversLicenseExpDate: Date = null;
   public genderTypeId: number = null;
   public maritalStatusId: number = null;
   public eligibleForRehire: boolean = null;
   public otherSpecialties: boolean = false;
   public probationStart: Date = null;
   public recallExpireDate: Date = null;
   public protectedVeteran: boolean = false;
   public veteranDischargeDate: Date = null;
   public defaultFacilitiesGrid: string = null;
   public defaultSchedulerView: string = null;
   public militaryTransportAuthorized: boolean = false;
   public tenantId: number = 0;
   public passportNumber: string = null;
   public dodNumber: string = null;
   public passportExp: Date = null;
   public dodNumberExp: Date = null;
   public defaultInventoryGrid: string = null;
   public accResponsePremiumProject: AccResponsePremiumProject[] = [];
   public accTimeEntryComments: AccTimeEntryComments[] = [];
   public accTimeEntryCommentsAudit: AccTimeEntryCommentsAudit[] = [];
   public accTimeEntryHeaderAcceptedBy: AccTimeEntryHeader[] = [];
   public accTimeEntryHeaderApprovedBy: AccTimeEntryHeader[] = [];
   public accTimeEntryHeaderAuditAcceptedBy: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderAuditApprovedBy: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderAuditEmployee: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderAuditModifiedBy: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderAuditReviewedBy: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderAuditSubmittedBy: AccTimeEntryHeaderAudit[] = [];
   public accTimeEntryHeaderEmployee: AccTimeEntryHeader[] = [];
   public accTimeEntryHeaderModifiedBy: AccTimeEntryHeader[] = [];
   public accTimeEntryHeaderReviewedBy: AccTimeEntryHeader[] = [];
   public accTimeEntryHeaderSubmittedBy: AccTimeEntryHeader[] = [];
   public allHughesNetUsers: AllHughesNetUsers[] = [];
   public clearanceStatus: HrClearanceStatus = null;
   public defaultPaySite: AllSites = null;
   public defaultPayTask: AccTimeEntryCodes = null;
   public defaultSite: AllSites = null;
   public department: HrDepartments = null;
   public driversLicenseState: AllStatesOrTerritories = null;
   public employeeJobAssignment: HrEmployeeJobAssignments = null;
   public employeeStatus: HrEmployeeStatus = null;
   public employmentStatus: HrEmploymentStatus = null;
   public employmentType: HrEmploymentType = null;
   public engCadDrawingHistory: EngCadDrawingHistory[] = [];
   public engCadDrawingsAssignedDrafterNavigation: EngCadDrawings[] = [];
   public engCadDrawingsReviewedByNavigation: EngCadDrawings[] = [];
   public facEquipmentDocument: FacEquipmentDocument[] = [];
   public facEquipmentMeterHistory: FacEquipmentMeterHistory[] = [];
   public facFuelIssuesLastUpdatedByNavigation: FacFuelIssues[] = [];
   public facFuelIssuesTransferredBy: FacFuelIssues[] = [];
   public facFuelLevelsLastUpdatedByNavigation: FacFuelLevels[] = [];
   public facFuelLevelsMeasuredByNavigation: FacFuelLevels[] = [];
   public facFuelOther: FacFuelOther[] = [];
   public facFuelPeriodStatusPreparedByPrimary: FacFuelPeriodStatus[] = [];
   public facFuelPeriodStatusPreparedBySecondary: FacFuelPeriodStatus[] = [];
   public facFuelReceiptsLastUpdatedByNavigation: FacFuelReceipts[] = [];
   public facFuelReceiptsReceivedByNavigation: FacFuelReceipts[] = [];
   public facGeneratorsDeadlinedDeadlinedBy: FacGeneratorsDeadlined[] = [];
   public facGeneratorsDeadlinedRepairedBy: FacGeneratorsDeadlined[] = [];
   public facMogasEstimatesEnteredByNavigation: FacMogasEstimates[] = [];
   public facMogasEstimatesLastUpdatedByNavigation: FacMogasEstimates[] = [];
   public facOilAdditions: FacOilAdditions[] = [];
   public facPressureReadings: FacPressureReadings[] = [];
   public facRefrigerantCylinders: FacRefrigerantCylinders[] = [];
   public facRefrigerantTransfers: FacRefrigerantTransfers[] = [];
   public facWasteDrumsClosedOutByNavigation: FacWasteDrums[] = [];
   public facWasteDrumsIssuedByNavigation: FacWasteDrums[] = [];
   public facWasteDrumsSealedByNavigation: FacWasteDrums[] = [];
   public facWeeklyStatusCompleted: FacWeeklyStatusCompleted[] = [];
   public genderType: HrGenderType = null;
   public homeState: AllStatesOrTerritories = null;
   public hrEmployeeXOtherSpecialtyType: HrEmployeeXOtherSpecialtyType[] = [];
   public hrEmployeeXShareholderType: HrEmployeeXShareholderType[] = [];
   public hrFyPayRatesHrmApprovedBy: HrFyPayRates[] = [];
   public hrFyPayRatesPmmApprovedBy: HrFyPayRates[] = [];
   public hrOcc: HrOcc[] = [];
   public hrPscDmAppEmp: HrPsc[] = [];
   public hrPscEmployee: HrPsc[] = [];
   public hrPscHrmAppEmp: HrPsc[] = [];
   public hrPscPmmAppEmp: HrPsc[] = [];
   public hrTravelBagDefaults: HrTravelBagDefaults[] = [];
   public mailingState: AllStatesOrTerritories = null;
   public maritalStatus: HrMaritalStatus = null;
   public ngPermissions: NgPermissions[] = [];
   public ngUsers: NgUsers[] = [];
   public opsScheduledAudit: OpsScheduledAudit[] = [];
   public opsScheduledEmployee: OpsScheduled[] = [];
   public opsScheduledReliefEmployee: OpsScheduled[] = [];
   public opsTravelRequestsApprovedBy: OpsTravelRequests[] = [];
   public opsTravelRequestsRequestedBy: OpsTravelRequests[] = [];
   public opsTravelRequestsTraveler: OpsTravelRequests[] = [];
   public opsVacationBidsEmployee: OpsVacationBids[] = [];
   public opsVacationBidsReliefEmployee: OpsVacationBids[] = [];
   public permanentSite: AllSites = null;
   public qcInspectionReportsInspector1: QcInspectionReports[] = [];
   public qcInspectionReportsInspector2: QcInspectionReports[] = [];
   public qcInspectionReportsInspector3: QcInspectionReports[] = [];
   public qcInspectionReportsInspector4: QcInspectionReports[] = [];
   public qcInspectionReportsSitePersonnel1: QcInspectionReports[] = [];
   public qcInspectionReportsSitePersonnel2: QcInspectionReports[] = [];
   public qcInspectionReportsSitePersonnel3: QcInspectionReports[] = [];
   public qcInspectionReportsSitePersonnel4: QcInspectionReports[] = [];
   public schResourcesCreatedBy: SchResources[] = [];
   public schResourcesModifiedBy: SchResources[] = [];
   public schScheduleItemCreatedBy: SchScheduleItem[] = [];
   public schScheduleItemEmployee: SchScheduleItem[] = [];
   public schScheduleItemModifiedBy: SchScheduleItem[] = [];
   public schScheduleItemReliefFor: SchScheduleItem[] = [];
   public schTravelRequestsApprovedBy: SchTravelRequests[] = [];
   public schTravelRequestsCreatedBy: SchTravelRequests[] = [];
   public schTravelRequestsEmployee: SchTravelRequests[] = [];
   public schTravelRequestsModifiedBy: SchTravelRequests[] = [];
   public schTravelRequestsQualityControlledBy: SchTravelRequests[] = [];
   public schTravelRequestsRequestedBy: SchTravelRequests[] = [];
   public tenant: NgTenants = null;
   public title: HrJobTitles = null;
   public trQtHistoryCertifiedBy: TrQtHistory[] = [];
   public trQtHistoryEmployee: TrQtHistory[] = [];
   public trQtHistoryRevokedBy: TrQtHistory[] = [];
   public trQtHistoryTrainedBy: TrQtHistory[] = [];
   public trQtNotRequired: TrQtNotRequired[] = [];
   public trQualHistoryApprovedBy: TrQualHistory[] = [];
   public trQualHistoryEmployee: TrQualHistory[] = [];
   public trQualHistoryRevokedBy: TrQualHistory[] = [];
   public trQualNotRequired: TrQualNotRequired[] = [];
   public trQualXAssigned: TrQualXAssigned[] = [];
   public trQuals: TrQuals[] = [];
   public trTrainers: TrTrainers[] = [];
   public ttt332CreatedBy: Ttt332[] = [];
   public ttt332PrimaryRequestor: Ttt332[] = [];
   public unionAffiliation: HrUnionAffiliation = null;
   public woClassificationHistoryEndEmployee: WoClassificationHistory[] = [];
   public woClassificationHistoryStartEmployee: WoClassificationHistory[] = [];
   public woScheduledMaintenanceTemplatesCreatedBy: WoScheduledMaintenanceTemplates[] = [];
   public woScheduledMaintenanceTemplatesLastUpdatedBy: WoScheduledMaintenanceTemplates[] = [];
   public woWorkOrderDetailLaborCreatedBy: WoWorkOrderDetailLabor[] = [];
   public woWorkOrderDetailLaborEmployee: WoWorkOrderDetailLabor[] = [];
   public woWorkOrderDetailLaborLastUpdatedBy: WoWorkOrderDetailLabor[] = [];
   public woWorkOrderDetailsCreatedBy: WoWorkOrderDetails[] = [];
   public woWorkOrderDetailsLastUpdatedBy: WoWorkOrderDetails[] = [];
   public woWorkOrdersCloseApprover: WoWorkOrders[] = [];
   public woWorkOrdersClosedByEmployee: WoWorkOrders[] = [];
   public woWorkOrdersLastUpdatedByNavigation: WoWorkOrders[] = [];
   public woWorkOrdersOpenApprover: WoWorkOrders[] = [];
   public woWorkOrdersRequestor: WoWorkOrders[] = [];
   public woWorkOrdersTttPrimaryRequestor: WoWorkOrders[] = [];
   public workSection: HrWorkSections = null;
}